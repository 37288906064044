import Link from "next/link";
import VideoCards from "../videos/VideoCards";

import { Videos } from "../../data/freeVideos";
import VideoCardSlider from "../videos/VideoCardSlider";
import SiteLink from "../common/SiteLink";

export function getVideos(idx) {
	return Videos.filter((course) => {
		if (
			course.id == parseInt(idx, 10) + 1 ||
			course.id == parseInt(idx, 10) + 2 ||
			course.id == parseInt(idx, 10) + 3 ||
			course.id == parseInt(idx, 10) + 4
		) {
			return true;
		} else {
			return false;
		}
	});
}

const FreeVideosSection = () => {
	return (
		<section
			id="freeVideos"
			aria-labelledby="free-asl-videos-title"
			className="pt-24 pb-32 bg-black overflow-hidden"
		>
			<div className="mx-auto xl:max-w-7xl">
				<div className="px-4 mx-auto text-center mb-14">
					<h2
						id="free-asl-videos-title"
						className="font-heading font-bold text-4xl mb-4 text-white"
					>
						Free American Sign Language Instructional Videos
					</h2>
					<p className="text-xl font-light text-white">
						We believe that ASL should be accessible to everyone regardless of
						economic means so we&apos;ve published our original videos for free!
					</p>
				</div>

				<VideoCardSlider videos={getVideos(1)} />

				<div className="flex justify-end">
					<SiteLink
						href="/free-lessons/asl-level-1"
						className="text-center py-3 px-4 max-w-1/4 text-white items-center"
					>
						<span>View all</span>
					</SiteLink>
				</div>
			</div>
		</section>
	);
};

export default FreeVideosSection;
