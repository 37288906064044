import CourseBannerSmall from "./CourseBannerSmall";

const AdultCourseSection = () => {
	return (
		<section
			id="adultAslCourses"
			aria-labelledby="asl-courses-title"
			className="max-w-7xl  my-14   lg:mx-auto"
		>
			<h2
				id="asl-courses-title"
				className="font-heading font-bold px-4 text-4xl mb-4 text-black"
			>
				ASL Courses
			</h2>
			<p className="text-left text-lg font-light px-4 mb-14 text-gray-700">
				Whether you&apos;re fully committed to learning ASL or just want to get
				your feet wet, we&apos;ve got the course for you. We&rsquo;ve even
				bundled our most popular courses for even greater savings!
			</p>
			<CourseBannerSmall type="Bundled" />
		</section>
	);
};

export default AdultCourseSection;
