import { useState } from "react";
import Link from "next/link";
import { HeartIcon } from "@heroicons/react/24/outline";
import Modal from "@/components/common/ScrollModal";
import BizReview from "./BizReview";

import Header2 from "../common/Header2";
import dynamic from "next/dynamic";
const WrappedLottiePlayer = dynamic(
	() => import("@/components/common/WrappedLottiePlayer"),
	{
		ssr: false,
	}
);

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const reviews = [
	{
		author: "Araylia",
		content:
			"They responded within a few hours and the problem was easily fixed. (Thank y'all so much)",
	},
	{
		author: "Mary",
		content:
			"Thank you for your assistance with my question. I’m grateful not only for the speed but the thoroughness of your answer and help going way out of your way to help me. I hope I can really use this info. That was great!!! Thanks a million!",
	},
	{
		author: "Jennifer",
		content:
			"The customer support team was very helpful and quick to reply. Thank you so much",
	},
	{
		author: "Patricia",
		content: "Fantastic fast service Love it :))",
	},
	{
		author: "Tracy",
		content:
			"Customer service reached out to me in about an hour after my initial contact. I will receive a full refund. No questions asked. Very easy refund.",
	},
	{
		author: "Kim",
		content: "We LOVE these courses!",
	},
	{
		author: "Sunshine",
		content:
			"I didn't know where to start, or how to start, learning. Your suggestions and information was just what I needed. Thank you",
	},
	{
		author: "Evanna",
		content: "That they responded so fast.",
	},
	{
		author: "Diana",
		content: "Timeliness and good explanation to answer my question",
	},
];

const BizReviews = ({}) => {
	const [openModal, setOpenModal] = useState(false);

	const handleShowMore = (e) => {
		e.preventDefault();
		setOpenModal(true);
	};

	const createReviews = (reviews) => {
		return reviews.slice(0, 5).map((review, index) => {
			return (
				<BizReview
					review={review}
					keyIndex={index}
					setOpenModal={setOpenModal}
					key={index}
				/>
			);
		});
	};

	return (
		<section
			id="businessReviews"
			aria-label="Reviews of our business"
			className="relative py-14 lg:py-20 bg-gray-50 overflow-hidden "
		>
			<Modal
				open={openModal}
				setOpen={setOpenModal}
				disableClose={true}
				aria-modal="true"
				role="dialog"
				aria-labelledby="All reviews"
			>
				<div className="flex flex-col px-4 py-8 space-y-8">
					{reviews.map((review, index) => {
						return (
							<div className="flex flex-col" key={`modal-${index}`}>
								<p className="text-lg mb-2">{review.author}</p>
								<p className="text-base text-gray-700">{review.content}</p>
							</div>
						);
					})}
				</div>
			</Modal>
			<div className="container  lg:px-4 max-w-7xl mx-auto">
				<div className="mb-16 mx-4 max-w-5xl lg:mx-auto">
					<div className="flex ">
						<div className="flex items-center  mx-auto">
							<div className="w-16">
								<WrappedLottiePlayer
									autoplay={true}
									hover={true}
									loop={false}
									src={`/img/home/icons/speech-bubble.json`}
								/>
							</div>
							{/* {[1].map((rating) => (
								<HeartIcon
									key={rating}
									className={classNames(
										"text-pink-700",
										"h-12 w-12 flex-shrink-0"
									)}
									aria-hidden="true"
								/>
							))} */}
						</div>
					</div>
					<Header2 className="mb-2 mt-4 text-center  text-black">
						We&apos;re proud of the feedback we receive about our customer
						support team
					</Header2>
				</div>
				<div className="flex pb-10 ">
					<div className="-m-2 lg:grid  lg:grid-cols-2 flex flex-nowrap  px-8 lg:px-0 snap-x snap-mandatory overflow-x-scroll lg:overflow-visible hide-scroll-bar">
						{createReviews(reviews)}
						{reviews.length > 5 && (
							<button
								onClick={(e) => {
									handleShowMore(e);
								}}
								className="group"
								aria-label="Show more reviews"
							>
								<div key="show-more-reviews" className=" snap-center ">
									<div className="p-2 ">
										<div className=" w-80 lg:w-auto lg:h-56 h-56 flex flex-col justify-center items-center  px-4 py-4  shadow-sm  text-gray-900 bg-white rounded-lg">
											<div className="mt-2">
												<p className="text-gray-700 font-semibold text-xl group-hover:underline underline-offset-2">
													Show more
												</p>
											</div>
										</div>
									</div>
								</div>
							</button>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default BizReviews;
