import { useState, useRef, useEffect } from "react";
import SiteLink from "../common/SiteLink";

const BizReview = ({ review, setOpenModal }) => {
	const contentRef = useRef();

	const handleShowMore = (e) => {
		e.preventDefault();
		setOpenModal(true);
	};

	const [isClamped, setClamped] = useState(false);

	useEffect(() => {
		// Function that should be called on window resize
		function handleResize() {
			if (contentRef && contentRef.current) {
				setClamped(
					contentRef.current.scrollHeight > contentRef.current.clientHeight
				);
			}
		}

		// Add event listener to window resize
		window.addEventListener("resize", handleResize);

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
	}, []); // Empty array ensures that it would only run on mount

	return (
		<div className=" snap-center ">
			<div className="p-2 ">
				<div className=" w-80 lg:w-auto lg:h-56 h-56 flex flex-col text-left  px-4 py-4  shadow-sm  text-gray-900 bg-white rounded-lg">
					<p className="font-semibold text-lg text-left mb-4">
						{review.author}
					</p>
					<p
						ref={contentRef}
						className="text-base h-24 font-light text-gray-900 line-clamp-4"
					>
						{review.content}
					</p>
					{isClamped && (
						<div className="mt-2 lg:hidden">
							<SiteLink
								color="black"
								href="#"
								onClick={(e) => {
									handleShowMore(e);
								}}
							>
								Show more
							</SiteLink>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default BizReview;
