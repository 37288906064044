import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import Link from "next/link";

export default function VideoCardSlider({ videos }) {
	return (
		<div className="flex ">
			<div
				className="flex w-full snap-x snap-mandatory px-8 lg:px-0 gap-x-4 xl:grid xl:grid-cols-4  overflow-scroll lg:overflow-visible hide-scroll-bar "
				aria-label="Scrollable videos section. Use arrow keys to scroll."
			>
				{videos.map((video) => (
					<Link
						href={
							video.paid
								? `/courses/${video.slug}`
								: `/free-lessons/asl-level-1/${video.slug}`
						}
						key={video.id}
						className="snap-center  p-2 lg:p-0"
						aria-label={
							video.paid
								? `View course: ${video.title}`
								: `View video: ${video.title} ${video.subtitle}`
						}
					>
						<div className="w-64 md:w-80  lg:w-full flex flex-col h-full relative">
							<div className="relative flex-shrink-0">
								<Image
									src={`/img/${video.img}`}
									alt={video.imageAlt}
									className="mb-1 rounded-md"
									width={500}
									height={279}
								/>

								{video.paid && (
									<div className="absolute flex flex-row bottom-3 bg-white shadow-md left-2 px-2 py-0.5 rounded-full">
										<CheckBadgeIcon className="h-5 w-5 text-green-500" />
										<p className="text-sm ml-1 text-black font-base">
											Get the course
										</p>
									</div>
								)}
							</div>
							<div className="flex-1 flex flex-col pb-4 mt-1.5">
								<h3 className=" text-sm font-medium  text-white group-hover:underline group-hover:underline-offset-1">
									{video.title}
								</h3>
							</div>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
}
