import BookCardsScrollable from "../books/BookCardsScrollable";
import SiteLink from "../common/SiteLink";

let workbooks = [
	{
		id: "10",
		handle: "getting-started-with-asl-course-workbook",
		title: "Getting Started with ASL - Course Worbook",
		images: [
			{
				url: "/img/book/getting-started-with-asl-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Sign Language 101 ASL Level 1 Course Workbook",
			},
		],
	},
	{
		id: "11",
		handle: "building-on-the-basics-of-asl-course-workbook",
		title: "Building on the Basics of ASL - Course Worbook",
		images: [
			{
				url: "/img/book/building-on-the-basics-of-asl-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Sign Language 101 ASL Level 2 Course Workbook",
			},
		],
	},
	{
		id: "12",
		handle: "sign-language-101-for-kids-level-1ab-course-workbook",
		title: "Sign Language 101 for Kids - Course Workbook",
		images: [
			{
				url: "/img/book/kids-course-workbook-cover.png",
				width: 612,
				height: 792,
				featuredImage: true,
				alt: "Sign Language 101 for Kids - Course Workbook",
			},
		],
	},
	{
		id: "7",
		handle: "deaf-in-america",
		title: "Deaf in America: Voices from a Culture",
		images: [
			{
				url: "/img/book/deaf-in-america.jpeg",
				width: 1200,
				height: 1880,
				featuredImage: true,
				alt: "Deaf in America",
			},
		],
	},
	{
		id: "8",
		handle: "american-sign-language-phrase-book",
		title: "The American Sign Language Phrase Book",
		images: [
			{
				url: "/img/book/asl-phrase-book.jpeg",
				width: 331,
				height: 500,
				featuredImage: true,
				alt: "American Sign Language Phrasebook",
			},
		],
	},
];

const BookshelfSection = () => {
	return (
		<section
			id="aslBooks"
			aria-labelledby="asl-books-title"
			className=" bg-white text-left pt-12 lg:pt-14 pb-8 lg:pb-14 text-black"
		>
			<div className="max-w-7xl mx-auto ">
				<h2
					id="asl-books-title"
					className="font-heading font-bold px-4 text-4xl mb-4 text-black"
				>
					American Sign Language Books
				</h2>
				<p className="px-4 mb-6 mt-6 lg:mb-10 text-lg font-light text-gray-700">
					We&apos;ve hand-picked books that will take you on a deeper dive into
					Deaf culture and ASL history as well as provide more guidance on your
					sign language journey.
				</p>

				<BookCardsScrollable bookData={workbooks} page={"home"} />

				<div className="flex px-4 mt-8 justify-end">
					<SiteLink href="/books" className="text-black">
						View all
					</SiteLink>
				</div>
			</div>
		</section>
	);
};

export default BookshelfSection;
