import { useState } from "react";
import LottieIcon from "@/components/common/LottieIcon";

const HighlightCard = ({ headline, description, image }) => {
	const [play, setPlay] = useState(false);

	return (
		<div
			className="flex flex-col  w-full "
			onMouseEnter={() => setPlay(true)}
			onMouseLeave={() => setPlay(false)}
		>
			<div className="flex justify-center pr-5 mb-6 ">
				<LottieIcon img={image} play={play} className={"w-16"} />
			</div>
			<div className="lg:w-5/6 mx-auto">
				<h3 className="text-2xl font-semibold mb-2 leading-tight">
					{headline}
				</h3>
				<p className="font-light text-lg text-gray-700 ">{description}</p>
			</div>
		</div>
	);
};

export default HighlightCard;
