import Image from "next/image";
import { ArrowRightCircle } from "lucide-react";
import Link from "next/link";
import Nav from "@/components/common/Nav";
import { Button } from "../common/Button";
import { urlFor } from "@/src/sanity/utils/portableText";

const HomeHeader = ({ block }) => {
	const { title, subtitle, leftImage, rightImage, cta } = block;
	return (
		<section className=" bg-[#4C5FEB] pb-6  relative overflow-hidden">
			<Nav showBackground={false} />
			{/* Hero section */}
			<div className="max-w-7xl mx-auto flex flex-col md:flex-row pt-4 md:pt-10 pb-10">
				<div className="md:w-3/12 px-8 relative hidden md:flex">
					<div className="md:absolute md:-top-8 md:w-3/4 md:-left-8 ">
						<Image
							src={urlFor(leftImage).url()}
							alt={
								leftImage.data.altText ||
								"learn sign language online from instrucor Dr. Byron Bridges"
							}
							width={leftImage?.data?.metadata?.dimensions?.width || 300}
							height={leftImage?.data?.metadata?.dimensions?.height || 308}
							priority={true}
						/>
					</div>
				</div>
				<div className="mx-2 md:w-6/12 mt-12">
					<h1 className="mb-6 lg:mb-4 font-heading tracking-tight leading-[3rem] md:leading-none text-center text-white text-5xl lg:text-6xl  font-extrabold md:font-bold">
						{title}
					</h1>
					<h2 className="mb-10  text-xl  text-center text-white ">
						{subtitle}
					</h2>
					<div className="mt-6 md:mt-0 md:w-3/12 px-8 relative flex flex-row md:hidden">
						<div className="pr-4">
							<Image
								src={urlFor(leftImage).url()}
								alt={
									leftImage.data.altText ||
									"learn sign language online from instrucor Dr. Byron Bridges"
								}
								width={leftImage?.data?.metadata?.dimensions?.width || 300}
								height={leftImage?.data?.metadata?.dimensions?.height || 308}
								className=""
								priority={true}
							/>
						</div>
						<div className="pl-4">
							<Image
								src={urlFor(rightImage).url()}
								alt={
									rightImage.data.altText ||
									"learn sign language online from instrucor Sandra Mae Frank"
								}
								width={rightImage?.data?.metadata?.dimensions?.width || 300}
								height={rightImage?.data?.metadata?.dimensions?.width || 315}
								className=""
								priority={true}
							/>
						</div>
					</div>
					<div className="text-center  mt-14">
						<Button
							href={cta?.primaryCta?.link}
							variant="solid"
							color="yellow"
							className="max-w-sm"
						>
							{cta?.primaryCta?.label}
						</Button>
					</div>
					<div className="mt-5 md:mt-6 flex justify-center">
						<Link
							href={cta?.secondaryCta?.link}
							className="group font-semibold text-md text-white uppercase ml-4 flex items-center hover:text-yellow-400"
						>
							<span className="pr-2">{cta?.secondaryCta?.label}</span>
							<ArrowRightCircle className="w-6 h-6 text-white group-hover:text-yellow-400 flex-col" />
						</Link>
					</div>
				</div>
				<div className="hidden md:w-3/12 px-8 md:flex relative">
					<div className="md:absolute -bottom-4 md:w-3/4 md:-right-8">
						<Image
							src={urlFor(rightImage).url()}
							alt={
								rightImage.data.altText ||
								"learn sign language online from instrucor Sandra Mae Frank"
							}
							width={rightImage?.data?.metadata?.dimensions?.width || 300}
							height={rightImage?.data?.metadata?.dimensions?.width || 315}
							className=""
							priority={true}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HomeHeader;
