import HighlightCard from "./HighlightCard";
import Header2 from "../common/Header2";
import Image from "next/image";

const bizHighlights = [
	{
		image: "mortarboard",
		headline: "Created by Deaf Language Experts!",
		description:
			"You'll benefit from the expertise of Deaf language experts, gaining a richer understanding of not just American Sign Language (ASL) but also its cultural nuances, ensuring an authentic and immersive learning experience.",
	},
	{
		image: "laptop",
		headline: "Access on the web or mobile app!",
		description:
			"We understand the importance of flexibility in today's fast-paced world. That's why our courses are accessible both on the web and through our user-friendly mobile app. Learn at your own pace, wherever and whenever it suits you best.",
	},
	{
		image: "diploma",
		headline: "We're accredited through IACET",
		description:
			"Rest assured that our courses meet high educational standards. We are accredited by the International Association for Continuing Education and Training (IACET), guaranteeing the quality and rigor of our ASL programs.",
	},
	{
		image: "school",
		headline: "Used by schools & organizations",
		description:
			"Many educational institutions and organizations trust our courses to provide top-notch ASL education. Join the ranks of those who have chosen our platform to meet their learning needs.",
	},
	{
		image: "shield",
		headline: "30-day money back guarantee!",
		description:
			"We're confident in the value our courses offer. If for any reason you're not satisfied within the first 30 days, we offer a hassle-free money-back guarantee. Your satisfaction is our priority.",
	},
	{
		image: "save-money",
		headline: "One low payment - access for a full year!",
		description:
			"Say goodbye to monthly subscription fees. With a single, affordable payment, you'll gain access to our ASL courses for a full year. No hidden costs or surprises, just a straightforward and cost-effective way to learn ASL.",
	},
];

const BizHighlightsSection = () => {
	return (
		<section
			id="businessHighlights"
			aria-label="Why you should choose us for your ASL education"
			className=" bg-gray-50 py-14  "
		>
			<div className="max-w-7xl  px-4 mx-auto bg-gray-50">
				<div className="flex flex-col w-full items-center justify-center mb-20">
					<Header2 className="mb-4 mt-2 text-5xl text-center">
						We&apos;re a top choice for ASL education!
					</Header2>
				</div>
				<div className="grid lg:grid-cols-2 gap-y-14 lg:gap-y-10 gap-x-6 px-4 md:px-10">
					{bizHighlights.map((highlight, index) => (
						<HighlightCard
							image={highlight.image}
							headline={highlight.headline}
							description={highlight.description}
							key={index}
						/>
					))}
				</div>
			</div>
		</section>
	);
};

export default BizHighlightsSection;
