import Link from "next/link";
import Image from "next/image";
import { default as imageUrlBuilder } from "@sanity/image-url";
import { dataset, projectId } from "@/sanity/env";
import { twMerge } from "tailwind-merge";
const builder = imageUrlBuilder({ projectId, dataset });

function urlFor(source) {
	return builder.image(source);
}

const BlogPostCard = ({ post, className }) => {
	return (
		<li key={post._id} className={twMerge("flex flex-col h-full", className)}>
			<Link
				href={`/blog/${post?.slug.current}`}
				className="group border border-gray-200 w-[21rem] lg:w-full  p-3 rounded-xl flex flex-col h-full gap-y-6"
			>
				{/* Top Content */}
				<div className="w-full h-36 bg-gray-100 rounded-lg flex justify-center items-center text-blue-500">
					{post?.mainImage ? (
						<Image
							src={urlFor(post?.mainImage).url()}
							alt={""}
							width={320}
							height={450}
							className="w-full h-full object-cover rounded-md"
							loading="lazy"
						/>
					) : (
						<div>Backup Image</div>
					)}
				</div>

				{/* Middle Content */}
				<div className="px-2 text-center w-full flex flex-col gap-y-4 flex-grow">
					<div className="px-2 py-1 text-xs rounded-md bg-blue-50 text-blue-600 text-center w-fit">
						{post?.categories?.[0]?.title || "Sign Language"}
					</div>
					<div>
						<h2 className="font-semibold text-lg leading-6 font-promo text-left group-hover:underline underline-offset-2">
							{post?.title}
						</h2>
						<p className="font-normal text-sm mt-0.5 leading-6 font-promo text-left group-hover:underline underline-offset-2">
							{post?.subtitle}
						</p>
					</div>
				</div>

				{/* Author Div - Fixed at the bottom */}
				<div className="mt-auto flex flex-row gap-x-2 px-2 items-center">
					<Image
						src={urlFor(post?.author?.image?.asset).url()}
						alt={""}
						width={25}
						height={25}
						className=""
						loading="lazy"
					/>
					<p className="text-sm font-prose text-gray-700">
						{post?.author?.name || "Author Name"}
					</p>
				</div>
			</Link>
		</li>
	);
};

export default BlogPostCard;
