import { Suspense } from "react";

import Image from "next/legacy/image";
import Link from "next/link";

import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { CheckCircleIcon } from "@heroicons/react/24/outline";

import Nav from "@/components/common/Nav";
import Footer from "@/components/common/FooterExpanded";

import WhyISignSection from "@/components/home/WhyISignSection";
import KidsCourseSection from "@/components/home/KidsCourseSection";
import FreeVideosSection from "@/components/home/FreeVideosSection";
import ProductsSkeletonGrid from "@/components/skeleton/productsSkeletonGrid";
import BookshelfSection from "@/components/home/BookShelfSection";
import AdultCourseSection from "@/components/home/AdultCourseSection";

import { Button } from "@/components/common/Button";
import BizReviews from "@/components/home/BizReviews";
import BizHighlightsSection from "@/components/home/BizHighlightsSection";
import MobileBanner from "@/components/home/MobileBanner";
import HighlightOnDemandLectures from "@/components/home/HighlightOnDemandLectures";
import HighlightAccess from "@/components/home/HighlightAccess";
import HighlightWorkbooks from "@/components/home/HighlightWorkbooks";
import { getPreviewClient } from "@/sanity/lib/client";
import { token } from "@/sanity/lib/token";
import { HOME_PAGE_QUERY } from "@/sanity/lib/queries";
import FeaturedBlogPosts from "@/components/home/FeaturedBlogPosts";
import HomeHeader from "@/components/home/HomeHeader";

export const getStaticProps = async ({ draftMode = false }) => {
	const client = getPreviewClient(draftMode ? token : undefined);
	const homepageData = await client.fetch(HOME_PAGE_QUERY);

	return {
		props: {
			title: homepageData?.seo?.metaTitle || "Learn Sign Language Online",
			ogTitle: homepageData?.seo?.metaTitle || "Learn Sign Language Online",
			ogDescription: homepageData?.seo?.metaDescription || "",
			ogUrl: "https://www.signlanguage101.com",
			description: homepageData?.seo?.metaDescription || "",
			canonical:
				homepageData?.seo?.canonicalUrl || "https://www.signlanguage101.com",
			structuredData: {
				__html: JSON.stringify({
					"@context": "http://schema.org",
					"@type": "Organization",
					name: "Sign Language 101",
					url: "https://www.signlanguage101.com",
					sameAs: [
						"https://www.facebook.com/signlanguage101",
						"https://www.instagram.com/sign.language101",
						"https://www.twitter.com/101sign",
						"https://www.tiktok.com/@101signlanguage",
						"https://www.pinterest.com/101signlanguage",
						"https://www.youtube.com/@signlanguage1013",
					],
					logo: "https://www.signlanguage101.com/web-app-manifest-192x192.png",
					contactPoint: {
						"@type": "ContactPoint",
						contactType: "Customer Service",
						email: "hello@support.signlanguage101.com",
					},
				}),
			},
			draftMode,
			token: draftMode ? token : "",
			homepageData,
		},
	};
};

export default function Home(props) {
	return (
		<div className="bg-white">
			{props?.homepageData?.pageBlocks &&
				props?.homepageData?.pageBlocks.map((block) => {
					switch (block._type) {
						case "homeHeaderBlock":
							return <HomeHeader key={block._id} block={block} />;
					}
				})}

			<main>
				<Suspense fallback={<ProductsSkeletonGrid />}>
					<HighlightOnDemandLectures />
					<AdultCourseSection />
					<HighlightAccess />
					<KidsCourseSection />
					<HighlightWorkbooks />
					<BookshelfSection />
					<WhyISignSection />
					{props?.homepageData?.pageBlocks &&
						props?.homepageData?.pageBlocks.map((block) => {
							switch (block._type) {
								case "featuredPosts":
									return <FeaturedBlogPosts key={block._id} block={block} />;
							}
						})}
					<BizHighlightsSection />
					<FreeVideosSection />
					<BizReviews />
					<MobileBanner />
				</Suspense>
			</main>
			<Footer />
		</div>
	);
}
