import Image from "next/image";
import Link from "next/link";
import dynamic from "next/dynamic";
const WrappedLottiePlayer = dynamic(
	() => import("@/components/common/WrappedLottiePlayer"),
	{
		ssr: false,
	}
);

import { ArrowLongRightIcon } from "@heroicons/react/24/outline";

const HighlightOnDemandLectures = () => {
	return (
		<section
			id="onDemandLectures"
			aria-labelledby="ondemand-lectures-title"
			className="relative pt-10 bg-[#F7FAFC]  mx-auto text-black overflow-hidden"
		>
			<div className="flex flex-col gap-x-4 md:flex-row max-w-7xl  mx-auto">
				<div className="w-full  mb-12 md:mb-0 px-4 pt-4 pb-6 md:pb-14 md:w-1/2 order-1 md:order-2">
					<div className="flex w-full justify-center md:justify-start">
						<div className="w-16 mb-4">
							<WrappedLottiePlayer
								autoplay={true}
								hover={true}
								loop={true}
								src={`/img/home/icons/play.json`}
							/>
						</div>
					</div>
					<h2
						id="ondemand-lectures-title"
						className="font-heading font-bold text-4xl mb-4 "
					>
						On-Demand lectures
					</h2>
					<p className="text-lg mb-8 ">
						Progress at your own pace, change the video speed, replay lessons,
						and review content as needed. Each course is packed with vocabulary,
						numbers, tips about learning American Sign Language, fingerspelling
						practice and special knowledge of Deaf culture.
					</p>
					<p className="text-lg mb-8 ">
						We&apos;ve structured each unit to span a week but you have the
						freedom to learn at your own pace. All videos include English closed
						captioning and voiceovers, ensuring an inclusive learning
						experience.
					</p>

					<Link
						href="/courses"
						className="hover:underline hover:underline-offset-2 uppercase font-semibold "
					>
						<p>
							VIEW COURSES{" "}
							<ArrowLongRightIcon className="w-5 h-5 inline-block" />
						</p>
					</Link>
				</div>

				<div className="overflow-hidden flex   md:relative  align-top  w-full md:w-1/2 order-2 md:order-1 ">
					<Image
						src="/img/home/course-player-md.png"
						alt="ASL video course player showing captions and playback controls"
						width={650}
						height={409}
						className="hidden xl:inline xl:absolute bottom-12 left-0"
					/>
					<Image
						src="/img/home/course-player-md.png"
						alt="ASL video course player showing captions and playback controls"
						width={650}
						height={409}
						className="hidden md:inline md:absolute bottom-16 left-0 xl:hidden"
					/>
					<Image
						src="/img/home/course-player-md.png"
						alt="ASL video course player showing captions and playback controls"
						width={650}
						height={409}
						className="md:hidden "
					/>
				</div>
			</div>
		</section>
	);
};

export default HighlightOnDemandLectures;
