export const Videos = [
	{
		id: "1",
		pageTitle: "About your ASL Expert - Dr. Bridges - Learn Sign Language",
		subtitle: "Your Instructor",
		title: "Learn About Dr. Bridges & Our ASL Courses.",
		slug: "about-dr-bridges",
		category: "Level 1",
		videoId: "495893288",
		bunnyVideoId: "747daca5-7daa-46ff-bafa-72f30ab6ef72",
		videoTitle: "Learn about Dr. Bridges & our ASL courses.",
		content:
			"<iframe title='Learn about Dr. Bridges & our courses with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/495893288?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_1_Video_1.jpg",
		imageAlt: "Free ASL class about Dr. Bridges",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn more about Dr. Bridges & our courses with our free sign language video lesson taught by a Deaf ASL expert. Start signing today!",
		metaDescription:
			"Learn more about Dr. Bridges & our courses with our free sign language video lesson taught by a Deaf ASL expert. Start signing today!",
		metaKeywords:
			"ASL, sign language, free lesson, Dr. Byron Bridges, ASL expert, learn ASL for free, beginner ASL",
		paid: false,
		runtime: { minutes: 3, seconds: 35 },
	},
	{
		id: "2",
		pageTitle: "Learn Sign Language Gestures you Already Know - Free Lesson",
		subtitle: "ASL Basics",
		title: "Learn Sign Language Gestures you Already Know",
		slug: "words-you-already-know",
		category: "Level 1",
		videoId: "495893377",
		bunnyVideoId: "bfd5881b-b626-4012-a6ab-e02230e514cb",
		videoTitle:
			"Learn about words you already know with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about words you already know with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/495893377?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_1_Video_2.jpg",
		imageAlt: "Free ASL class about words you already know",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn basic ASL gestures you already know with free, introductory lessons from a Deaf expert. Discover familiar signs in American Sign Language today!",
		metaDescription:
			"Learn basic ASL gestures you already know with free, introductory lessons from a Deaf expert. Discover familiar signs in American Sign Language today!",
		metaKeywords:
			"ASL, sign language, free lesson, common ASL words, ASL vocabulary, beginner ASL, sign language basics",
		paid: false,
		runtime: { minutes: 5, seconds: 42 },
	},
	{
		id: "3",
		pageTitle: "Master ASL Facial Expressions: Pro Tips - Free Video Lesson",
		subtitle: "ASL Basics",
		title: "Learn About the Importance of Facial Expressions in ASL",
		slug: "facial-expressions",
		category: "Level 1",
		videoId: "495893550",
		bunnyVideoId: "2d7c7fc0-cbbd-4b3e-b090-b92960db0810",
		videoTitle:
			"Learn about facial expressions with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about facial expressions with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/495893550?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_1_Video_3.jpg",
		imageAlt: "Free ASL class about facial expressions",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn vital facial expressions in ASL with free lessons from a Deaf expert. Master essential components of sign language communication.",
		metaDescription:
			"Learn vital facial expressions in ASL with free lessons from a Deaf expert. Master essential components of sign language communication.",
		metaKeywords:
			"ASL, sign language, free lesson, facial expressions in ASL, ASL grammar, non-manual markers, beginner ASL",
		paid: false,
		runtime: { minutes: 1, seconds: 43 },
	},
	{
		id: "4",
		pageTitle: "Essential Body Language in ASL: Quick Guide - Free Lessons",
		subtitle: "ASL Basics",
		title: "Learn About the Importance of Body Language in ASL",
		slug: "body-language",
		category: "Level 1",
		videoId: "495893642",
		bunnyVideoId: "bacafb31-a397-49e0-81e7-feac98e04da2",
		videoTitle:
			"Learn about body language in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about body language with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/495893642?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_1_Video_4.jpg",
		imageAlt: "Free ASL class about body language",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn essential body language in ASL with free lessons from a Deaf expert. Learn crucial non-verbal communication skills for signing.",
		metaDescription:
			"Learn essential body language in ASL with free lessons from a Deaf expert. Learn crucial non-verbal communication skills for signing.",
		metaKeywords: "ASL, Sign Language, Free Lesson, body language",
		paid: false,
		runtime: { minutes: 2, seconds: 19 },
	},
	{
		id: "5",
		pageTitle: "Essential ASL Numbers: 1-10 Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to sign numbers 1 to 10 in ASL",
		slug: "numbers-1-to-10",
		category: "Level 1",
		videoId: "495893703",
		bunnyVideoId: "c98dc177-8be6-4df2-9078-4840ca6059e0",
		videoTitle:
			"Learn about the numbers 1 to 10 with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about the numbers 1 to 10 with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/495893703?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_1_Video_5.jpg",
		imageAlt: "Free ASL class about numbers 1 to 10",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign numbers 1 to 10 with free, introductory ASL lessons from a Deaf expert. Master basic number signs in American Sign Language today!",
		metaDescription:
			"Learn to sign numbers 1 to 10 with free, introductory ASL lessons from a Deaf expert. Master basic number signs in American Sign Language today!",
		metaKeywords:
			"ASL, sign language, free lesson, numbers in ASL, learn numbers, beginner ASL course",
		paid: false,
		runtime: { minutes: 0, seconds: 51 },
	},
	{
		id: "ad-1",
		title: "ASL Essentials Bundle",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/asl-essentials-bundle_sm.png",
		slug: "asl-essentials-bundle",
		paid: true,
		imageAlt: "ASL Essentials Bundle",
	},
	{
		id: "6",
		pageTitle: "Pro Tips: How to Read ASL Signs Better - Free Expert Guide",
		subtitle: "Language tip",
		title: "Pro Tips for Understanding and Reading ASL Signs Effectively",
		slug: "language-tip-reading-signs",
		category: "Level 1",
		videoId: "495893741",
		bunnyVideoId: "232d4e73-82fd-4b0b-8dd3-e64634a5f243",
		videoTitle:
			"Learn about how to read signs with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about how to read signs with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/495893741?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_1_Video_6.jpg",
		imageAlt: "Free ASL class about how to read signs",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Improve your ASL receptive skills with expert techniques for reading signs. Learn proven strategies to understand signers better. Free video guide.",
		metaDescription:
			"Improve your ASL receptive skills with expert techniques for reading signs. Learn proven strategies to understand signers better. Free video guide.",
		metaKeywords: "ASL, Sign Language, Free Lesson, language tips",
		paid: false,
		runtime: { minutes: 1, seconds: 11 },
	},
	{
		id: "7",
		pageTitle: "Master the ASL Alphabet Today: Quick Guide - Free Lessons",
		subtitle: "ASL Basics",
		title: "Learn to Sign the ASL Alphabet",
		slug: "the-abcs",
		category: "Level 1",
		videoId: "496150867",
		bunnyVideoId: "87109014-4631-46bb-98a8-3855bd51382e",
		videoTitle:
			"Learn about the ABCs in ASL our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about the ABCs in ASL our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496150867?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_2_Video_2.jpg",
		imageAlt: "Free ASL class about the ABCs in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Master the ASL alphabet with clear video demonstrations. Learn fingerspelling A-Z with practice tips from expert instructors. Start signing your name today!",
		metaDescription:
			"Master the ASL alphabet with clear video demonstrations. Learn fingerspelling A-Z with practice tips from expert instructors. Start signing your name today!",
		metaKeywords: "asl, sign language, free lesson, abc",
		paid: false,
		runtime: { minutes: 4, seconds: 18 },
	},
	{
		id: "8",
		pageTitle: "Learn Color Signs Fast: Free ASL Video Guide - Basic Signs",
		subtitle: "Vocabulary",
		title: "Learn to Sign Colors in ASL",
		slug: "colors",
		category: "Level 1",
		videoId: "496150915",
		bunnyVideoId: "becadd08-7989-49cf-8ec3-04d0c83f0732",
		videoTitle:
			"Learn about colors in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about colors in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496150915?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_2_Video_3.jpg",
		imageAlt: "Free ASL class about colors in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free introductory ASL lessons on colors! Watch expert-led videos and learn basic signs for colors in American Sign Language today.",
		metaDescription:
			"Free introductory ASL lessons on colors! Watch expert-led videos and learn basic signs for colors in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, colors",
		paid: false,
		runtime: { minutes: 1, seconds: 36 },
	},
	{
		id: "9",
		pageTitle: "Learn Numbers 11-20 in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 11 to 20 in ASL",
		slug: "numbers-11-to-20",
		category: "Level 1",
		videoId: "496150935",
		bunnyVideoId: "cb6368a5-8219-4ac1-97b8-f061707e6f63",
		videoTitle:
			"Learn about numbers 11 to 20 in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 11 to 20 in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496150935?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_2_Video_4.jpg",
		imageAlt: "Free ASL class about numbers 11 to 20 in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign numbers 11 to 20 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaDescription:
			"Learn to sign numbers 11 to 20 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 55 },
	},
	{
		id: "10",
		pageTitle: "Pro ASL Tips: Master Hand Shapes & Positions - Free Lesson",
		subtitle: "Language Tip",
		title: "Pro Tip on How to Master Hand Shapes & Positions in ASL",
		slug: "language-tip-hand-shapes-and-positions",
		category: "Level 1",
		videoId: "496150955",
		bunnyVideoId: "8cdcb95e-fdd7-4e54-a00c-e9a9e8db0275",
		videoTitle:
			"Learn about hand shapes and positions in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about hand shapes and positions in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496150955?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_2_Video_5.jpg",
		imageAlt: "Free ASL class about hand shapes and positions in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on hand shapes! Watch expert-led videos to improve your signing technique and precision in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on hand shapes! Watch expert-led videos to improve your signing technique and precision in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, hand shapes",
		paid: false,
		runtime: { minutes: 0, seconds: 49 },
	},
	{
		id: "ad-1",
		title: "Getting Started with ASL Course",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/level-1a-image_SM.png",
		slug: "level-1a-online-asl-course",
		paid: true,
		imageAlt: "Getting Started with ASL Course",
	},
	{
		id: "11",
		pageTitle: "Master ASL Pronouns: Quick Video Guide - Free Lessons",
		subtitle: "Vocabulary",
		title: "Master ASL Pronouns",
		slug: "pronouns",
		category: "Level 1",
		videoId: "496150981",
		bunnyVideoId: "33cf1aa6-365a-40be-8dcc-09d9a3cacdda",
		videoTitle:
			"Learn about pronouns in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about pronouns in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496150981?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_2_Video_6.jpg",
		imageAlt: "Free ASL class about pronouns",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn pronouns in ASL with free lessons from a Deaf expert. Master personal, possessive, and other pronouns in American Sign Language today!",
		metaDescription:
			"Learn pronouns in ASL with free lessons from a Deaf expert. Master personal, possessive, and other pronouns in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, pronouns",
		paid: false,
		runtime: { minutes: 2, seconds: 24 },
	},
	{
		id: "13",
		pageTitle:
			"Free ASL Lessons - Learn Everyday Phrases in American Sign Language",
		subtitle: "Vocabulary",
		title: "Learn to Sign Phrases You'll use Everyday in ASL",
		slug: "everyday-phrases",
		category: "Level 1",
		videoId: "496153760",
		bunnyVideoId: "5b6cdb46-08ed-4684-b6a1-fe3d98764c93",
		videoTitle:
			"Learn about everyday phrases in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about everyday phrases in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496153760?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_3_Video_2.jpg",
		imageAlt: "Free ASL class about everyday phrases in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn everyday phrases in ASL with free lessons from a Deaf expert. Master useful phrases for daily conversations in American Sign Language today!",
		metaDescription:
			"Learn everyday phrases in ASL with free lessons from a Deaf expert. Master useful phrases for daily conversations in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, everyday phrases",
		paid: false,
		runtime: { minutes: 7, seconds: 43 },
	},
	{
		id: "14",
		pageTitle: "Quick ASL Numbers Guide: Signs 21-30 - Free Video Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 21 to 30 in ASL",
		slug: "numbers-21-to-30",
		category: "Level 1",
		videoId: "496153853",
		bunnyVideoId: "f0ebcf6c-7836-42b9-adaf-b8f584c9aa1f",
		videoTitle:
			"Learn about numbers 21 to 30 in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 21 to 30 in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496153853?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_3_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 21 to 30 in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on numbers 21-30! Watch expert-led videos and master number signs in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on numbers 21-30! Watch expert-led videos and master number signs in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 53 },
	},
	{
		id: "15",
		pageTitle: "Which Hand to Sign With in ASL? Expert Guide - Free Lessons",
		subtitle: "Language Tip",
		title: "Which Hand Should You Use for ASL?",
		slug: "left-or-right-hand",
		category: "Level 1",
		videoId: "496153869",
		bunnyVideoId: "85fd0021-d293-47f9-9407-67d5c32e07e3",
		videoTitle:
			"Learn about signing with your left or right hand with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about signing with your left or right hand with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496153869?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_3_Video_4.jpg",
		imageAlt: "Free ASL class about signing with your left or right hand",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn proper hand dominance for ASL signing. Get clear answers about right vs left hand usage, plus essential technique tips. Free guide!",
		metaDescription:
			"Learn proper hand dominance for ASL signing. Get clear answers about right vs left hand usage, plus essential technique tips. Free guide!",
		metaKeywords: "asl, sign language, free lesson, hand",
		paid: false,
		runtime: { minutes: 0, seconds: 37 },
	},
	{
		id: "16",
		pageTitle: "Essential ASL Phrases: Quick Start Guide - Free Video Lessons",
		subtitle: "Vocabulary",
		title: "Learn Essential ASL Phrases",
		slug: "common-phrases",
		category: "Level 1",
		videoId: "496153883",
		bunnyVideoId: "3ea2a98f-f87c-4549-bbb5-29b50cdf3720",
		videoTitle:
			"Learn about common phrases with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about common phrases with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496153883?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_3_Video_5.jpg",
		imageAlt: "Free ASL class about common phrases",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn common ASL phrases with free lessons from a Deaf expert. Master everyday phrases in American Sign Language and improve your signing skills today!",
		metaDescription:
			"Learn common ASL phrases with free lessons from a Deaf expert. Master everyday phrases in American Sign Language and improve your signing skills today!",
		metaKeywords: "asl, sign language, free lesson, common phrases",
		paid: false,
		runtime: { minutes: 2, seconds: 41 },
	},
	{
		id: "ad-1",
		title: "Building on the Basics of ASL Course",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/level-1b-image_SM.png",
		slug: "level-1b-online-asl-course",
		paid: true,
		imageAlt: "Building on the Basics of ASL Course",
	},
	{
		id: "18",
		pageTitle: "Learn to Sign Antonyms in American Sign Language - ASL Video",
		subtitle: "Vocabulary",
		title: "Learn to Sign Common Antonyms in ASL",
		slug: "antonyms",
		category: "Level 1",
		videoId: "496155555",
		bunnyVideoId: "cb588d64-58e0-48c0-b4a3-a4c73c4635ad",
		videoTitle:
			"Learn about antonyms in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about antonyms in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496155555?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_4_Video_2.jpg",
		imageAlt: "Free ASL class about antonyms in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign common antonyms in ASL with free lessons from a Deaf expert. Master opposite words in American Sign Language today!",
		metaDescription:
			"Learn to sign common antonyms in ASL with free lessons from a Deaf expert. Master opposite words in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, antonyms",
		paid: false,
		runtime: { minutes: 5, seconds: 10 },
	},
	{
		id: "19",
		pageTitle: "Learn Numbers 31-40 in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 31 to 40 in ASL",
		slug: "numbers-31-to-40",
		category: "Level 1",
		videoId: "496155604",
		bunnyVideoId: "e69bdb00-42b8-47c0-b98a-4301521168b5",
		videoTitle:
			"Learn about numbers 31 to 40 in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 31 to 40 in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496155604?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_4_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 31 to 40",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on numbers 31-40! Watch expert-led videos to master number signs in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on numbers 31-40! Watch expert-led videos to master number signs in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 56 },
	},
	{
		id: "20",
		pageTitle: "Master Facial Expressions in Sign Language - ASL Tips",
		subtitle: "Language Tip",
		title: "Pro Tips for Using Facial Expressions in ASL",
		slug: "tip-facial-expressions",
		category: "Level 1",
		videoId: "496155626",
		bunnyVideoId: "edd39357-8093-48a4-9f61-ddd611c8d2dd",
		videoTitle:
			"Learn about facial expresssions in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about facial expresssions in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496155626?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_4_Video_4.jpg",
		imageAlt: "Free ASL class about facial expressions",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn the importance of facial expressions in ASL with free lessons from a Deaf expert. Master expressive signing in American Sign Language today!",
		metaDescription:
			"Learn the importance of facial expressions in ASL with free lessons from a Deaf expert. Master expressive signing in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, facial expressions",
		paid: false,
		runtime: { minutes: 0, seconds: 46 },
	},
	{
		id: "21",
		pageTitle: "Master Even More Common ASL Phrases - Learn Sign Langauge",
		subtitle: "Vocabulary",
		title: "Master Even More Common ASL Phrases",
		slug: "more-common-phrases",
		category: "Level 1",
		videoId: "496155654",
		bunnyVideoId: "4abe566a-3b62-48a2-8033-75eeab9fd6f9",
		videoTitle:
			"Learn about more common phrases in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about more common phrases in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496155654?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_4_Video_5.jpg",
		imageAlt: "Free ASL class about more common phrases in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn more common phrases in ASL with free lessons from a Deaf expert. Master everyday phrases in American Sign Language and start signing today!",
		metaDescription:
			"Learn more common phrases in ASL with free lessons from a Deaf expert. Master everyday phrases in American Sign Language and start signing today!",
		metaKeywords: "asl, sign language, free lesson, more common phrases",
		paid: false,
		runtime: { minutes: 2, seconds: 56 },
	},
	{
		id: "23",
		pageTitle: "Essential Home Signs in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Vocabulary",
		title: "Learn Essential Home Signs in ASL",
		slug: "around-the-house",
		category: "Level 1",
		videoId: "496157023",
		bunnyVideoId: "9d506457-e6fd-49f4-a558-b01c9a5a438f",
		videoTitle:
			"Learn about words around the house in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about words around the house in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496157023?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_5_Video_2.jpg",
		imageAlt: "Free ASL class about words around the house in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn common household signs in ASL with free video lessons from a Deaf expert. Master everyday vocabulary for items around your home.",
		metaDescription:
			"Learn common household signs in ASL with free video lessons from a Deaf expert. Master everyday vocabulary for items around your home.",
		metaKeywords: "asl, sign language, free lesson, house items, household",
		paid: false,
		runtime: { minutes: 5, seconds: 35 },
	},
	{
		id: "ad-1",
		title: "Complete Course Bundle",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/complete-bundle-image_SM.png",
		slug: "complete-course-bundle",
		paid: true,
		imageAlt: "Complete Course Bundle",
	},
	{
		id: "24",
		pageTitle: "Master Numbers 41-50 in ASL: Free Video Tutorial",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 41 to 50 in ASL",
		slug: "numbers-40-to-50",
		category: "Level 1",
		videoId: "496157093",
		bunnyVideoId: "f5f3dc05-6cd6-4e2c-b768-da2c36622535",
		videoTitle:
			"Learn about numbers 41 to 50 in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 41 to 50 in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496157093?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_5_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 41 to 50 in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on numbers 41-50! Watch expert-led videos to master number signs in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on numbers 41-50! Watch expert-led videos to master number signs in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 56 },
	},
	{
		id: "25",
		pageTitle: "Master ASL Fingerspelling: Quick Tips & Practice - Free Guide",
		subtitle: "Language Tip",
		title: "Master ASL Fingerspelling: Quick Tips & Practice",
		slug: "how-to-improve-fingerspelling",
		category: "Level 1",
		videoId: "496157139",
		bunnyVideoId: "555a19c3-deb3-4fe0-bb7d-182a1f07950c",
		videoTitle:
			"Learn how to improve fingerspelling with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn how to improve fingerspelling with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496157139?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_5_Video_4.jpg",
		imageAlt: "Free ASL class about how to improve fingerspelling",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lesson on improving fingerspelling! Watch expert-led videos and get better at spelling words with American Sign Language today.",
		metaDescription:
			"Free ASL lesson on improving fingerspelling! Watch expert-led videos and get better at spelling words with American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, fingerspelling",
		paid: false,
		runtime: { minutes: 0, seconds: 36 },
	},
	{
		id: "26",
		pageTitle: "Learn to Sign Hobbies using ASL - Sign Language Lesson",
		subtitle: "Vocabulary",
		title: "Learn to Sign Hobbies using ASL",
		slug: "hobbies",
		category: "Level 1",
		videoId: "496157200",
		bunnyVideoId: "024545b5-ddce-4399-85a6-829c3b77fc50",
		videoTitle:
			"Learn about hobbies in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about hobbies in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496157200?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_5_Video_5.jpg",
		imageAlt: "Free ASL class about hobbies in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign hobbies like bowling & skating in ASL with free lessons from a Deaf expert. Master signs for activities and hobbies in American Sign Language today!",
		metaDescription:
			"Learn hobbies like bowling & skating in ASL with our free sign language video lesson taught by a Deaf ASL expert. Start signing today!",
		metaKeywords: "asl, sign language, free lesson, hobbies",
		paid: false,
		runtime: { minutes: 3, seconds: 31 },
	},
	{
		id: "28",
		pageTitle: "Learn to Sign About People in ASL - Free Video Lesson",
		subtitle: "Vocabulary",
		title: "Learn to Sign About People in ASL",
		slug: "people",
		category: "Level 1",
		videoId: "496183497",
		bunnyVideoId: "b3744509-9f54-408d-94e9-9fa22a16bdba",
		videoTitle:
			"Learn to sign words about people in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn to sign words about people in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496183497?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_6_Video_2.jpg",
		imageAlt: "Free ASL class about people in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on people signs! Watch expert-led videos to learn signs for family members and other people in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on people signs! Watch expert-led videos to learn signs for family members and other people in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, people",
		paid: false,
		runtime: { minutes: 5, seconds: 34 },
	},
	{
		id: "29",
		pageTitle: "Learn Body Signs in American Sign Language - Free Video",
		subtitle: "Vocabulary",
		title: "Learn Body Signs in American Sign Language",
		slug: "the-body",
		category: "Level 1",
		videoId: "496183568",
		bunnyVideoId: "285c1c3e-345f-4df8-bc5b-c8adfcdd4e67",
		videoTitle:
			"Learn about the body with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about the body with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496183568?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_6_Video_3.jpg",
		imageAlt: "Free ASL class about the body",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn ASL signs for body parts with free lessons from a Deaf expert. Master essential body-related vocabulary in American Sign Language today!",
		metaDescription:
			"Learn ASL signs for body parts with free lessons from a Deaf expert. Master essential body-related vocabulary in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, body",
		paid: false,
		runtime: { minutes: 2, seconds: 31 },
	},
	{
		id: "ad-1",
		title: "Elevating your Signing Skills Course",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/level-2a-image_SM.png",
		slug: "level-2a-online-asl-course",
		paid: true,
		imageAlt: "Elevating your Signing Skills Course",
	},
	{
		id: "30",
		pageTitle: "Quick ASL Numbers Guide: Signs 51-60 - Free Video Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 51 to 60 in ASL",
		slug: "numbers-51-to-60",
		category: "Level 1",
		videoId: "496183608",
		bunnyVideoId: "cc83a180-a1f8-4151-9fa9-df695184b051",
		videoTitle:
			"Learn about numbers 51 to 60 with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 51 to 60 with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496183608?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_6_Video_4.jpg",
		imageAlt: "Free ASL class about numbers 51 to 60",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign numbers 51 to 60 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaDescription:
			"Learn to sign numbers 51 to 60 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 50 },
	},
	{
		id: "31",
		pageTitle:
			"Free ASL Lessons – ASL Expert Tip: Masculine and feminine signs",
		subtitle: "Language Tip",
		title: "Tips for Using Masculine and Feminine Signs in ASL",
		slug: "masculine-and-feminine-signs",
		category: "Level 1",
		videoId: "496183673",
		bunnyVideoId: "2db33e4c-53c1-40a3-8649-53279790821f",
		videoTitle:
			"Learn about masculine and feminine signs with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about masculine and feminine signs with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496183673?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_6_Video_5.jpg",
		imageAlt: "Free ASL class about masculine and feminine signs",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on masculine and feminine signs! Watch expert-led videos to improve your American Sign Language skills today.",
		metaDescription:
			"Free ASL lessons on masculine and feminine signs! Watch expert-led videos to improve your American Sign Language skills today.",
		metaKeywords: "asl, sign language, free lesson, masculine, feminine",
		paid: false,
		runtime: { minutes: 0, seconds: 40 },
	},
	{
		id: "32",
		pageTitle: "Master ASL Clothing Signs - Free Quick Video Tutorial",
		subtitle: "Vocbulary",
		title: "Learn ASL Clothing Signs",
		slug: "clothes",
		category: "Level 1",
		videoId: "496183717",
		bunnyVideoId: "32b7814a-1af9-4429-a196-88a3d75e99c2",
		videoTitle:
			"Learn about clothes in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about clothes in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496183717?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_6_Video_6.jpg",
		imageAlt: "Free ASL class about clothes",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on clothes! Watch expert-led videos to learn signs for clothing items like shirts, hats, and more in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on clothes! Watch expert-led videos to learn signs for clothing items like shirts, hats, and more in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, clothes",
		paid: false,
		runtime: { minutes: 2, seconds: 42 },
	},
	{
		id: "34",
		pageTitle: "Master Time Signs in ASL: Quick Guide - Free Video Tips",
		subtitle: "Vocabulary",
		title: "How to Sign Time Expressions in ASL",
		slug: "time",
		category: "Level 1",
		videoId: "496185284",
		bunnyVideoId: "01726156-40e6-4821-a7eb-b3ff7b2f3c78",
		videoTitle:
			"Learn about time in ASL with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about time in ASL with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496185284?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_7_Video_2.jpg",
		imageAlt: "Free ASL class about time in ASL",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn time expressions in ASL through clear video lessons. Master hours, minutes, and everyday time phrases. Free expert tutorial with practice tips.",
		metaDescription:
			"Learn time expressions in ASL through clear video lessons. Master hours, minutes, and everyday time phrases. Free expert tutorial with practice tips.",
		metaKeywords: "asl, sign language, free lesson, time",
		paid: false,
		runtime: { minutes: 6, seconds: 4 },
	},
	{
		id: "35",
		pageTitle: "Learn Numbers 61-70 in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 61 to 70 in ASL",
		slug: "numbers-61-to-70",
		category: "Level 1",
		videoId: "496185354",
		bunnyVideoId: "c8cec630-0f7e-47e1-9973-a795096903d2",
		videoTitle:
			"Learn about numbers 61 to 70 with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 61 to 70 with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496185354?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_7_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 61 to 70",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign numbers 61 to 70 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaDescription:
			"Learn to sign numbers 61 to 70 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 54 },
	},
	{
		id: "ad-1",
		title: "Refining your ASL Skills Course",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/level-2b-image_SM.png",
		slug: "level-2b-online-asl-course",
		paid: true,
		imageAlt: "Refining your ASL Skills Course",
	},
	{
		id: "36",
		pageTitle: "Learn Months in ASL: Quick Video Guide - Free Sign Language",
		subtitle: "Language Tip",
		title: "Learn to Sign All 12 Months in ASL",
		slug: "months-of-the-year",
		category: "Level 1",
		videoId: "496185399",
		bunnyVideoId: "e57bf65a-4cb2-4299-b49c-cab957a11428",
		videoTitle:
			"Learn about months of the year with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about months of the year with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496185399?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_7_Video_4.jpg",
		imageAlt: "Free ASL class about months of the year",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn how to sign all 12 months in ASL with clear, easy-to-follow video demos. Master January through December in minutes with expert instruction. Free guide!",
		metaDescription:
			"Learn how to sign all 12 months in ASL with clear, easy-to-follow video demos. Master January through December in minutes with expert instruction. Free guide!",
		metaKeywords: "asl, sign language, free lesson, months",
		paid: false,
		runtime: { minutes: 0, seconds: 56 },
	},
	{
		id: "37",
		pageTitle: "Master ASL Calendar Signs: Days, Weeks & More - Free Lessons",
		subtitle: "Vocabulary",
		title: "Learn Calendar Signs in ASL: Days, Weeks, and Time Expressions",
		slug: "calendar",
		category: "Level 1",
		videoId: "496185436",
		bunnyVideoId: "5921cd5a-ffd0-4498-aed8-08c0ce9f284d",
		videoTitle:
			"Learn about calendar words with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about calendar words with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496185436?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_7_Video_5.jpg",
		imageAlt: "Free ASL class about calendar words",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn essential ASL calendar signs - days, weeks, and months. Master time expressions and dates with clear video demos. Free practice guide.",
		metaDescription:
			"Learn essential ASL calendar signs - days, weeks, and months. Master time expressions and dates with clear video demos. Free practice guide.",
		metaKeywords: "asl, sign language, free lesson, calendar",
		paid: false,
		runtime: { minutes: 6, seconds: 28 },
	},
	{
		id: "39",
		pageTitle: "Essential ASL Verbs: Quick Video Guide - Free Lessons",
		subtitle: "Vocabulary",
		title: "Learn to Sign Verbs in ASL",
		slug: "verbs",
		category: "Level 1",
		videoId: "496186348",
		bunnyVideoId: "cae0f2ce-7569-45ec-bd3d-2f4ae65743ad",
		videoTitle:
			"Learn about verbs with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about verbs with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496186348?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_8_Video_2.jpg",
		imageAlt: "Free ASL class about verbs",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign verbs in ASL with free lessons taught by a Deaf expert. Improve your American Sign Language skills and master essential verbs today!",
		metaDescription:
			"Learn to sign verbs in ASL with free lessons taught by a Deaf expert. Improve your American Sign Language skills and master essential verbs today!",
		metaKeywords: "asl, sign language, free lesson, verbs",
		paid: false,
		runtime: { minutes: 3, seconds: 38 },
	},
	{
		id: "40",
		pageTitle: "Learn Numbers 71-80 in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 71 to 80 in ASL",
		slug: "numbers-71-to-80",
		category: "Level 1",
		videoId: "496186395",
		bunnyVideoId: "4d11c8ca-5d9e-437c-a5f3-92bd75f17f34",
		videoTitle:
			"Learn about numbers 71 to 80 with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 71 to 80 with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496186395?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_8_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 71 to 80",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign numbers 71 to 80 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaDescription:
			"Learn to sign numbers 71 to 80 in ASL with free lessons from a Deaf expert. Improve your number-signing skills in American Sign Language today!",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 0, seconds: 55 },
	},
	{
		id: "41",
		pageTitle: "ASL Grammar: Past, Present & Future - Free Quick Lesson",
		subtitle: "Language Tip",
		title: "Learn to Sign Past, Present, and Future Tense in ASL",
		slug: "past-present-future",
		category: "Level 1",
		videoId: "496186440",
		bunnyVideoId: "8cea1c6a-96fa-4e60-a60a-72b5cb07f48a",
		videoTitle:
			"Learn about signing about past, present, and future events with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about signing the past, present, and future with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496186440?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_8_Video_4.jpg",
		imageAlt: "Free ASL class about signing the past, present, and future",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign past, present, and future in ASL with clear examples. Master time tenses through practice exercises. Free expert grammar video guide.",
		metaDescription:
			"Learn to sign past, present, and future in ASL with clear examples. Master time tenses through practice exercises. Free expert grammar video guide.",
		metaKeywords:
			"asl, sign language, free lesson, past, present, future, tense",
		paid: false,
		runtime: { minutes: 0, seconds: 32 },
	},
	{
		id: "ad-1",
		title: "Complete Course Bundle",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/complete-bundle-image_SM.png",
		slug: "complete-course-bundle",
		paid: true,
		imageAlt: "Complete Course Bundle",
	},
	{
		id: "42",
		pageTitle: "Giving Directions in Sign Language - Free ASL Lessons",
		subtitle: "Vocabulary",
		title: "Learn How to Give Directions in ASL",
		slug: "directions",
		category: "Level 1",
		videoId: "496186536",
		bunnyVideoId: "476b1254-1fbb-4569-8cee-dcd5073926ca",
		videoTitle:
			"Learn about directions with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about directions with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496186536?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_8_Video_5.jpg",
		imageAlt: "Free ASL class about directions",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lesson on giving directions! Watch expert-led videos and improve your sign language skills for giving directions today.",
		metaDescription:
			"Free ASL lesson on giving directions! Watch expert-led videos and improve your sign language skills for giving directions today.",
		metaKeywords: "asl, sign language, free lesson, directions",
		paid: false,
		runtime: { minutes: 4, seconds: 43 },
	},
	{
		id: "44",
		pageTitle:
			"Free ASL Lessons – Sign Fruits & Vegetables in American Sign Language",
		subtitle: "Vocabulary",
		title: "Learn to Sign Fruits & Vegetables in ASL",
		slug: "fruits-and-vegetables",
		category: "Level 1",
		videoId: "496187192",
		bunnyVideoId: "360a4288-9201-440a-bb19-c388ed586c7c",
		videoTitle:
			"Learn about fruits & vegetables with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about fruits & vegetables with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496187192?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_9_Video_2.jpg",
		imageAlt: "Free ASL class about fruits & vegetables",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn to sign fruits and vegetables in ASL with free lessons from a Deaf expert. Expand your sign language vocabulary and start signing today!",
		metaDescription:
			"Learn to sign fruits and vegetables in ASL with free lessons from a Deaf expert. Expand your sign language vocabulary and start signing today!",
		metaKeywords: "asl, sign language, free lesson, fruits, vegetables",
		paid: false,
		runtime: { minutes: 2, seconds: 27 },
	},
	{
		id: "45",
		pageTitle: "Learn Numbers 81-90 in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 81 to 90 in ASL",
		slug: "numbers-81-to-90",
		category: "Level 1",
		videoId: "496187237",
		bunnyVideoId: "f82abb23-3583-4bce-964e-f243c4ca598c",
		videoTitle:
			"Learn about numbers 81 to 90 with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 81 to 90 with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496187237?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_9_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 81 to 90",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lesson on numbers 81-90! Watch expert-led videos and master number signs in American Sign Language today.",
		metaDescription:
			"Free ASL lesson on numbers 81-90! Watch expert-led videos and master number signs in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, numbers,",
		paid: false,
		runtime: { minutes: 0, seconds: 58 },
	},
	{
		id: "46",
		pageTitle: "Master Even More Food & Drink Signs in Sign Language - Video",
		subtitle: "Vocabulary",
		title: "Learn Even More Food & Drink Signs in ASL",
		slug: "more-food-and-drinks",
		category: "Level 1",
		videoId: "496187529",
		bunnyVideoId: "9852c9b3-ec5c-4821-8569-b6ae2ad26613",
		videoTitle:
			"Learn about more food and drinks with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about more food and drinks with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496187529?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_9_Video_4.jpg",
		imageAlt: "Free ASL class about more food and drinks",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons on food and drinks! Watch expert-led videos and master common food-related signs in American Sign Language today.",
		metaDescription:
			"Free ASL lessons on food and drinks! Watch expert-led videos and master common food-related signs in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, food, drinks",
		paid: false,
		runtime: { minutes: 6, seconds: 30 },
	},
	{
		id: "48",
		pageTitle: "ASL Eye Contact: Essential Skills - Free Expert Guide",
		subtitle: "Language Tip",
		title: "ASL Eye Contact: Essential Skills",
		slug: "more-eye-contact",
		category: "Level 1",
		videoId: "496188951",
		bunnyVideoId: "208a3dc5-09d6-4662-9c16-6e607e8dc334",
		videoTitle:
			"Learn more about eye contact with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn more about eye contact with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496188951?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_9_Video_6.jpg",
		imageAlt: "Free ASL class about eye contact",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Master proper eye contact in ASL communication. Learn essential cultural rules and techniques for effective signing. Free expert video guide included.",
		metaDescription:
			"Master proper eye contact in ASL communication. Learn essential cultural rules and techniques for effective signing. Free expert video guide included.",
		metaKeywords: "asl, sign language, free lesson, eye contant",
		paid: false,
		runtime: { minutes: 0, seconds: 40 },
	},
	{
		id: "ad-1",
		title: "Elevating your Signing Skills Course",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/level-2a-image_SM.png",
		slug: "level-2a-online-asl-course",
		paid: true,
		imageAlt: "Elevating your Signing Skills Course",
	},
	{
		id: "49",
		pageTitle: "Learn Animal Signs in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Vocabulary",
		title: "Learn Animal Signs in ASL",
		slug: "animals",
		category: "Level 1",
		videoId: "496189042",
		bunnyVideoId: "b4eb4de0-4cdd-4cca-8b9e-bd5b2c848a57",
		videoTitle:
			"Learn about animals with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about animals with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496189042?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_10_Video_2.jpg",
		imageAlt: "Free ASL class about animals",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn animal signs in ASL with free videos taught by a Deaf ASL expert. Master common sign language for animals and enhance your ASL skills today!",
		metaDescription:
			"Learn animal signs in ASL with free videos taught by a Deaf ASL expert. Master common sign language for animals and enhance your ASL skills today!",
		metaKeywords: "asl, sign language, free lesson, animals",
		paid: false,
		runtime: { minutes: 4, seconds: 24 },
	},
	{
		id: "50",
		pageTitle: "Learn Numbers 91-100 in ASL: Quick Video Guide - Free Lessons",
		subtitle: "Numbers",
		title: "Learn to Sign Numbers 91 to 100 in ASL",
		slug: "numbers-91-to-100",
		category: "Level 1",
		videoId: "496189235",
		bunnyVideoId: "2a024827-c512-45e7-8529-b9dee5abb2df",
		videoTitle:
			"Learn about numbers 91 to 100 with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about numbers 91 to 100 with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496189235?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_10_Video_3.jpg",
		imageAlt: "Free ASL class about numbers 91 to 100",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Free ASL lessons to sign numbers 91-100! Watch videos led by a Deaf expert and improve your number signing skills in American Sign Language today.",
		metaDescription:
			"Free ASL lessons to sign numbers 91-100! Watch videos led by a Deaf expert and improve your number signing skills in American Sign Language today.",
		metaKeywords: "asl, sign language, free lesson, numbers",
		paid: false,
		runtime: { minutes: 1, seconds: 0 },
	},
	{
		id: "51",
		pageTitle:
			"Free ASL Lessons – Pro Tip: How to increase your ASL signing speed",
		subtitle: "Language Tip",
		title: "Pro Tips for Increasing Your ASL Signing Speed",
		slug: "signing-speed",
		category: "Level 1",
		videoId: "496189590",
		bunnyVideoId: "a583c6c2-70e5-4b86-89c0-e327ee254a30",
		videoTitle:
			"Learn about signing speed with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about signing speed with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496189590?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_10_Video_4.jpg",
		imageAlt: "Free ASL class about signing speed",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Want to increase your signing speed? Learn about how with our free sign language video lesson taught by a Deaf ASL expert.",
		metaDescription:
			"Want to increase your signing speed? Learn about how with our free sign language video lesson taught by a Deaf ASL expert.",
		metaKeywords: "asl, sign language, free lesson, language tip",
		paid: false,
		runtime: { minutes: 0, seconds: 49 },
	},
	{
		id: "52",
		pageTitle: "Quickly Master Sports Signs in American Sign Language - Free",
		subtitle: "Vocabulary",
		title: "Learn Sports Signs in ASL",
		slug: "sports",
		category: "Level 1",
		videoId: "496189714",
		bunnyVideoId: "8a56f26c-fe93-47bf-a308-9330b1296bbf",
		videoTitle:
			"Learn about sports with our free sign language video lesson taught by a Deaf ASL expert.",
		content:
			"<iframe title='Learn about sports with our free sign language video lesson taught by a Deaf ASL expert.' src='https://player.vimeo.com/video/496189714?dnt=1&autoplay=1' width='640' height='360' frameborder='0' allow='autoplay; fullscreen' allowfullscreen></iframe>",
		img: "free-videos/Level_1_Lesson_10_Video_5.jpg",
		imageAlt: "Free ASL class about sports",
		instructor: "Dr. Byron Bridges",
		instructorLink: "#",
		linkText: "Watch video",
		ogDescription:
			"Learn sports signs in ASL with free online lessons taught by a Deaf ASL expert. Master sign language for sports terms and boost your signing skills today!",
		metaDescription:
			"Learn sports signs in ASL with free online lessons taught by a Deaf ASL expert. Master sign language for sports terms and boost your signing skills today!",
		metaKeywords:
			"ASL, sign language, free lesson, sports in ASL, hobbies in ASL, beginner ASL course",
		paid: false,
		runtime: { minutes: 2, seconds: 25 },
	},
	{
		id: "ad-1",
		title: "Elevating your Signing Skills Course",
		subtitle: "HD Videos, Quizzes, and More!",
		img: "course/level-2a-image_SM.png",
		slug: "level-2a-online-asl-course",
		paid: true,
		imageAlt: "Elevating your Signing Skills Course",
	},
];
