import { Dialog, DialogBackdrop, DialogTitle } from "@headlessui/react";
import { XMarkIcon, ChevronLeftIcon } from "@heroicons/react/24/outline";
import { Button } from "./Button";
import { twMerge } from "tailwind-merge";

export default function Modal({
	open,
	setOpen,
	title,
	disableClose = false,
	children,
	video = false,
	hideHeader = false,
	width = "lg:w-7/12",
	className,
}) {
	return (
		<Dialog
			as="div"
			className={"fixed z-50 inset-0  lg:grid lg:place-items-center"}
			onClose={() => setOpen(false)}
			open={open ?? false}
		>
			<div className=" font-modals flex items-center justify-center pt-0  pb-0 text-center sm:block sm:p-0">
				<DialogBackdrop className="fixed inset-0 bg-black/30" />
				<div
					className={twMerge(
						"relative  inline-block align-bottom bg-white lg:rounded-3xl overflow-hidden  pt-0  text-left  lg:shadow-xl transform transition-all lg:my-8 sm:align-middle w-full h-screen  lg:h-[80vh]",
						video && " ",
						width,
						className
					)}
				>
					{hideHeader && (
						<>
							<button
								type="button"
								className="bg-gray-100 absolute  z-50 top-4 right-4   rounded-full px-2 py-2 text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={() => setOpen(false)}
							>
								<span className="sr-only">Close</span>
								<XMarkIcon className="h-5 w-5 block" aria-hidden="true" />
							</button>
							{/* <button
								type="button"
								className="bg-gray-100 absolute md:hidden z-50 top-4 left-4   rounded-full px-2 py-2 text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
								onClick={() => setOpen(false)}
							>
								<span className="sr-only">Close</span>
								<ChevronLeftIcon
									className="h-5 w-5 md:hidden"
									aria-hidden="true"
								/>
							</button> */}
						</>
					)}
					<div className="flex flex-col max-h-[100%]">
						{!hideHeader && (
							<div className="flex pt-4 pb-1 flex-col h-1/8">
								<div className="flex flex-row items-center    ">
									<div className="w-[12] pl-4 pb-2 pr-4 ">
										<button
											type="button"
											className="bg-gray-100  absolute  z-50 top-4 right-4   rounded-full px-2 py-2 text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
											onClick={() => setOpen(false)}
										>
											<span className="sr-only">Close</span>
											<XMarkIcon className="h-5 w-5 block" aria-hidden="true" />
										</button>
										{/* <button
											type="button"
											className="bg-gray-100 absolute md:hidden z-50 top-4 left-4   rounded-full px-2 py-2 text-black hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
											onClick={() => setOpen(false)}
										>
											<span className="sr-only">Close</span>
											<ChevronLeftIcon
												className="h-5 w-5 md:hidden"
												aria-hidden="true"
											/>
										</button> */}
									</div>
									<div className="w-full">
										<DialogTitle
											as="h3"
											className="mt-0 mr-12  text-center text-base leading-6 font-medium font-heading text-gray-900 flex justify-center"
										>
											{title}
										</DialogTitle>
									</div>
								</div>
							</div>
						)}

						<div className="relative overflow-y-auto">
							<div className="sm:mt-0 sm:ml-0 sm:text-left">
								<div
									className={twMerge(
										" mb-0 overflow-hidden",
										video && "mb-0 mx-4"
									)}
								>
									<div className="flex relative items-stretch  overflow-y-auto">
										{children}
									</div>
								</div>
							</div>
						</div>
						{!disableClose && (
							<div className="w-full h-1/8 bg-white border-t border-gray-100 ">
								<div className="px-4 py-2 mb-0">
									<Button
										variant="outline"
										color="slate"
										onClick={() => setOpen(false)}
									>
										Close
									</Button>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</Dialog>
	);
}
