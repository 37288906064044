export default function ProductsSkeletonGrid({ count }) {
	return (
		<div className="">
			<div className=" max-w-7xl mx-auto py-16  overflow-hidden sm:py-24">
				<div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-4">
					{Array(4)
						.fill(count)
						.map((item, index) => {
							return (
								<div className="group text-sm" key={index}>
									<div className="w-full aspect-w-1  overflow-hidden ">
										<div className="skeleton-box rounded-2xl shadow-sm h-40 w-full mb-2 mr-2"></div>
										<div className="skeleton-box rounded-sm shadow-sm h-6 w-full mb-1 mr-2"></div>
										<div className="skeleton-box rounded-sm shadow-sm h-8 w-full mb-2 mr-2"></div>
										<div className="skeleton-box rounded-sm shadow-sm h-14 w-full mb-0 mr-2"></div>
									</div>
								</div>
							);
						})}
				</div>
			</div>
		</div>
	);
}
