import Image from "next/image";
import Link from "next/link";

function classNames(...classes) {
	return classes.filter(Boolean).join(" ");
}

const sanitizeUrl = (url) => {
	return url.replace(/[^\x20-\x7E]/g, ""); // Remove non-printable characters
};

export default function VideoCards({ videos }) {
	return (
		<div
			className={classNames(
				videos.length > 3
					? "md:grid-cols-3 lg:grid-cols-4"
					: `md:grid-cols-${videos.length}`,
				"grid grid-cols-2 gap-3 "
			)}
		>
			{videos.map((video) => {
				const { _id, title, subtitle, slug, mainVideo } = video;

				return (
					<Link
						href={`/free-lessons/asl-level-1/${slug}`}
						key={_id}
						className="group"
						aria-label={`Navigate to ${title} ${subtitle}`}
					>
						<div className="col-span-1 flex flex-col h-full text-left relative">
							<div className="relative flex-shrink-0">
								<Image
									src={sanitizeUrl(
										`https://image.mux.com/${mainVideo?.video?.asset?.playbackId}/thumbnail.png?time=${mainVideo?.video?.asset?.thumbTime ? mainVideo?.video?.asset?.thumbTime : 15}`
									)}
									alt={title}
									className="mb-1 rounded-md"
									width={500}
									height={279}
								/>
							</div>
							<div className="flex-1 flex flex-col mt-1">
								<h3 className="mt-0 text-sm font-medium  text-gray-800 group-hover:underline group-hover:underline-offset-1">
									{title}
								</h3>
							</div>
						</div>
					</Link>
				);
			})}
		</div>
	);
}
