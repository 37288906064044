import CourseBannerSmall from "./CourseBannerSmall";

const KidsCourseSection = () => {
	return (
		<section
			id="kidsAslCourses"
			aria-labelledby="kids-asl-courses-title"
			className="max-w-7xl my-14  mx-auto"
		>
			<h2
				id="kids-asl-courses-title"
				className="font-heading font-bold text-4xl mx-4 mb-4 text-black"
			>
				ASL Courses for Kids
			</h2>
			<p className="text-left text-lg font-light px-4 mb-14 text-gray-700">
				Get your little ones signing with confidence through our fun ASL courses
				designed especially for kids! Dive into a world of interactive lessons
				and cool activities that&apos;ll have them signing like pros in no time.
			</p>
			<CourseBannerSmall type="Kids" />
		</section>
	);
};

export default KidsCourseSection;
