import { useRef, forwardRef } from "react";
import Image from "next/image";
import { twMerge } from "tailwind-merge";
import dynamic from "next/dynamic";
const WrappedLottiePlayer = dynamic(
	() => import("@/components/common/WrappedLottiePlayer"),
	{
		ssr: false,
	}
);

// const LottieChild = forwardRef((props, ref) => {
// 	<WrappedLottiePlayer {...props} ref={ref} />;
// });

const LottieIcon = ({ img, play = false, playOnHover = true, className }) => {
	const playerRef = useRef(null);

	// if (playerRef && playerRef.current) {
	// 	if (play) {
	// 		playerRef.current.play();
	// 	} else {
	// 		playerRef.current.stop();
	// 	}
	// }

	return (
		<div className={twMerge("w-12", className)}>
			<WrappedLottiePlayer
				autoplay={true}
				hover={playOnHover}
				loop={false}
				ref={playerRef}
				src={`/img/home/icons/${img}.json`}
			/>

			{/* <Image src={`/img/home/icons/${img}.gif`} width={640} height={640} /> */}
		</div>
	);
};

export default LottieIcon;
