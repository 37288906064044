import Image from "next/image";
import { Button } from "../common/Button";

const WhyISignSection = () => {
	return (
		<section
			id="whyISign"
			aria-labelledby="why-i-sign-title"
			className="pt-14 pb-14 relative bg-cover bg-no-repeat bg-center h-124"
		>
			<Image
				src="/img/home/DrByronBridges3.png"
				alt="Dr Byron Bridges"
				fill={true}
				className="z-0 absolute object-cover object-center"
			/>
			<div className="absolute z-10 flex flex-row justify-end container mx-auto py-14 md:pt-24 md:pb-24 px-4">
				<div className="flex flex-col md:w-1/2 ">
					<div className="flex justify-content-end mt-6 overlay-content">
						<div className="pt-4 flex flex-col">
							<h2
								id="why-i-sign-title"
								className="text-3xl font-heading mb-6 font-semibold text-black text-center"
							>
								What made you decide to learn Sign Language?
							</h2>
							<p className="text-center text-lg  mb-0">
								We&apos;d love to hear what brought you here today. Are you
								learning sign language for work, school or to communicate with a
								new friend or family member?
							</p>
						</div>
					</div>

					<div className="text-center pt-8 pb-6 ">
						<Button
							variant="solid"
							color="slate"
							className="md:w-1/2"
							href="/contact-us"
						>
							Let us know!
						</Button>
					</div>
				</div>
			</div>
		</section>
	);
};

export default WhyISignSection;
