import Image from "next/image";
import Header2 from "../common/Header2";
import { Button } from "../common/Button";

const MobileBanner = () => {
	return (
		<section
			id="mobileAccess"
			aria-label="Mobile Access"
			className="relative pt-20 bg-white max-w-7xl mx-auto text-black overflow-hidden"
		>
			<div className=" flex flex-col md:flex-row  mx-auto">
				<div className="w-full mb-12 md:mb-0 px-4 pt-12 md:w-1/2">
					<Header2 className="mb-5 font-bold">
						Access your courses on the go!
					</Header2>
					<p className="mb-16 text-gray-700 font-light text-lg">
						Download our free mobile app to access your courses anywhere you
						have an internet connection.
					</p>
					<div className="flex justify-center">
						<Button
							variant="solid"
							color="slate"
							href="/mobile-app"
							className="mr-4 max-w-sm"
						>
							Find Out How
						</Button>
					</div>
				</div>

				<div className="overflow-hidden h-96  md:relative  align-bottom w-full md:w-1/2">
					<Image
						src="/img/mobile/mobile-collection-small-top.png"
						fill={true}
						alt="Sign Language 101 ASL courses on mobile app"
						className="hidden md:inline md:absolute md:bottom-0 md:left-0"
						style={{ objectFit: "cover", objectPosition: "bottom left" }}
					/>
					<Image
						src="/img/mobile/top-mobile-course.png"
						width={600}
						height={600}
						alt="Sign Language 101 ASL courses on mobile app"
						className="md:hidden "
					/>
				</div>
			</div>
		</section>
	);
};

export default MobileBanner;
