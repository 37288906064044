import BlogPostCard from "../blog/BlogPostCard";
import SiteLink from "../common/SiteLink";

const FeaturedBlogPosts = ({ block }) => {
	const { posts, title, description } = block;
	if (!posts || posts.length == 0) return <></>;
	return (
		<section
			id="aslBlog"
			aria-labelledby="asl-blog-title"
			className="max-w-7xl  my-14   lg:mx-auto"
		>
			<h2
				id="asl-blog-title"
				className="font-heading font-bold px-4 text-4xl mb-4 text-black"
			>
				{title}
			</h2>
			<p className="text-left text-lg font-light px-4 mb-14 text-gray-700">
				{description}
			</p>
			<div className="flex  w-full">
				<div
					className="flex w-full snap-x snap-mandatory px-8  gap-x-4 xl:grid xl:grid-cols-3  overflow-scroll lg:overflow-visible hide-scroll-bar "
					aria-label="Scrollable blog section. Use arrow keys to scroll."
				>
					{posts.map((post) => {
						return (
							<BlogPostCard
								key={post._id}
								post={post}
								className={"w-96 snap-center  items-center"}
							/>
						);
					})}
				</div>
			</div>
			<div className="flex px-4 mt-8 justify-end">
				<SiteLink href="/blog" className="text-black">
					View all
				</SiteLink>
			</div>
		</section>
	);
};

export default FeaturedBlogPosts;
